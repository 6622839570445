.footer {
  width: 100%;
  padding: 16px 0px 0px;
  @screen md {
    margin: 0 24px 30px;
    width: unset;
  }
  @screen lg {
    padding: 0px 24px;
    margin: 60px auto 80px;
    width: 100%;
    max-width: 1150px;
  }
  @screen xl {
    padding: 0px;
    margin: 60px auto 80px;
    width: 100%;
  }
}

.footerInner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  padding: 12px 24px;
  background: #f0f3f8;
  border-radius: 0px;
  box-shadow:
    0px 7px 17.899999618530273px 0px #1d33581c,
    0px 0px 9px 0px #1d33580d;

  @screen md {
    flex-direction: row;
    width: auto;
    border-radius: 100px;
  }
  @screen lg {
  }
  @screen xl {
  }
}

.links {
  display: flex;
  flex-direction: column;
  @screen md {
    gap: 32px;
    flex-direction: row;
  }
  @screen lg {
    gap: 64px;
    flex-direction: row;
  }
}

.links a {
  padding: 16px 0;
  line-height: 14px;
  @screen md {
    padding: 0;
    line-height: 28px;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
  @screen md {
    gap: 32px;
    margin: 0;
  }
  @screen xl {
    gap: 64px;
  }
}

.links a,
.social a {
  cursor: pointer;
  letter-spacing: 2px;
  color: var(--blue-100);
  @screen md {
    letter-spacing: normal;
  }
}

.socialContainer {
  display: flex;
  gap: 8px;
}

.social svg {
  display: inline;
  height: 21px;
  width: 21px;
}
