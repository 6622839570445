.navigation {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--blue-100);
  z-index: 99;
  @screen lg {
    max-width: 1150px;
    margin: 0 auto;
    padding: 24px;
  }
  @screen xl {
    padding: 24px 0;
  }
}

.hide {
  position: fixed;
  top: -9999em;
  left: -9999em;
}

.logo {
  min-width: 120px;
  @screen lg {
    min-height: 48px;
    margin: calc((88px - 48px) / 2);
    margin-left: 0;
    min-width: 180px;
  }
}

.logoAnimation {
  width: 220px;
  height: 88px;
  transform: translateX(-18px);
}

.navigation .navLinks {
  display: none;
  align-items: center;
  gap: 40px;
  margin: 0 10px;
  @screen md {
    display: flex;
    gap: 25px;
    font-size: 15px;
  }
  @screen lg {
    gap: 50px;
    font-size: 18px;
  }
  @screen xl {
    gap: 66px;
  }
}

.connectionButton {
  display: none;
  color: var(--blue-100);
  @screen md {
    display: block;
  }
}

.navigation .connectedButton,
.navigation .connectButton,
.navigation .connectButtonPlaceholder {
  font-family: var(--font-roboto-mono);
  width: 100%;
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  box-sizing: unset;
  @screen md {
    width: 172px;
  }
}
.navigation .connectButtonPlaceholder {
  display: none;
  @screen md {
    display: block;
  }
}

.navigation .connectButton {
  background: var(--blue-100);
  color: #fff;
}

.navigation .connectedButton {
  background: var(--blue-transparent);
  color: var(--blue-100);
  display: flex;
  gap: 0;
  align-items: center;
  padding: 0 8px 0 2px;
  border-radius: 45px;
  border: 1px solid var(--blue-100);
  backdrop-filter: blur(20px);
}

.navigation .connectedButton div:nth-child(2) {
  flex: 1;
  font-size: 16px;
}

.navigation .connectButton {
  border: 1px solid var(--blue-100);
  border-radius: 45px;
  font-weight: 400;
}

.hamburger {
  display: block;
  @screen md {
    display: none;
  }
}

.slidingMenu {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  top: 100px;
  right: 0;
  width: 223px;
  height: 600px;
  color: var(--white);
  background-color: var(--blue-100);
  z-index: 100;
  padding: 24px;
  transform: translateX(100%);
  transition:
    transform 0.3s ease-in-out,
    width 0.1s linear 0.1s,
    padding 0.1s linear 0.1s;
  @screen md {
    display: none;
  }
}

.slidingMenu .connectionButton {
  display: block;
}

.slidingMenu.closed {
  width: 0;
  padding: 0;
  overflow: hidden;
}

.slidingMenu.open {
  transform: translateX(0);
}

.slidingMenu a {
  font-size: 17px;
  font-weight: 300;
}

.slidingMenu .connectButton,
.slidingMenu .connectedButton {
  border: 1px solid var(--white);
  color: white;
}
