@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--gray);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--gray-30);
  border-radius: 12px;
}

html,
body {
  overflow-x: hidden;
}

body {
  --blue-160: #1d3358;
  --blue-130: #403d60;
  --blue-105: #305492;
  --blue-100: #207dca;
  --blue-95: #3f6ec0;
  --blue-50: #576a84;
  --blue-20: #8ca8d9;
  --blue-18: #97b1dd;
  --blue-15: #98a7bb;
  --blue-11: #b1c1d5;
  --blue-10: #b7c8ea;
  --blue-1: #dde9fe;
  --blue-transparent: #16152d02;

  --yellow-200: #fdc54e;
  --yellow-100: #fdd277;
  --yellow-20: #f9ebcd;

  --red-100: #f20000;

  --gray-30: #8a8a8a;
  --gray-10: #cdd3db;
  --gray-9: #cbc9e4;
  --gray-5: #f0f3f8;
  --gray-1: #e6edeb;
  --gray: #ecf1ef;

  --white: #ffffff;
  --black: #000000;

  --toastify-toast-width: 416px;
  --toastify-color-light: var(--blue-100);
  --toastify-text-color-light: white;
  --toastify-close-button-light: white;
  background: var(--gray);
  font-size: 16px;
  font-weight: 400;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  overflow-y: auto;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.15) 0%, var(--gray) 66.35%), url('/background-resized-mobile.jpg');
  background-size: calc(500px);
  background-repeat: no-repeat;
  background-position: top right;

  @screen md {
    background-size: calc(800px);
  }

  @screen lg {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.15) 0%, var(--gray) 40.35%), url('/background-resized-main.jpg');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 1000px;
    align-items: center;
  }
  @screen xl {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.15) 0%, var(--gray) 80.35%), url('/background-resized-main.jpg');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 1000px;
    align-items: center;
  }
  @media screen and (min-width: 2200px) {
    background-image: url('/background-resized-main.jpg');
  }
}

.wrapper.no-bg-image {
  background: var(--gray);
}

main {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 20px;
  max-width: 1150px;
  @screen md {
    margin-left: 100px;
    margin-right: 100px;
  }
  @screen lg {
    margin-left: 100px;
    margin-right: 100px;
  }
  @screen 2xl {
    margin-left: 200px;
    margin-right: 200px;
  }
}

.toast {
  /* height: 86px; */
  border: 16px solid white;
  border-radius: 10px;
  box-shadow:
    0 0 35px 0 rgba(0, 0, 0, 0.2),
    0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background: var(--blue-100);
  color: #fff;
}
.Toastify__close-button {
  color: white !important;
  opacity: 1 !important;
}

/* Carousel globals */
.carousel .slide {
  transform: scale(96%);
  transition: linear 0.4s transform;
  cursor: pointer;
}

.carousel .slide.selected {
  transform: scale(100%);
}

.carousel .slider-wrapper {
  overflow: visible !important;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.carousel .control-dots {
  bottom: -40px !important;
}

.carousel .control-dots .dot {
  background: #c2c2c2 !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  background: #207dca !important;
}

/* Global Tooltip Styling */
.react-tooltip {
  color: var(--blue-100) !important;
  padding: 10px !important;
  width: 240px !important;
  font-family: 'Roboto' !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  text-align: center !important;
  background-color: white !important;
  box-shadow: 0px 2px 8px 2px #00000050 !important;
}
